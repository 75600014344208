import { useEffect, useState } from 'react'
import { Analytics } from '@genoa/analytics'

import { ConsentManagementConsentPayload, useAmplitude } from '../amplitude'
import { useAnalytics } from '../analytics'
import { useLogger } from '../logger'
import { ReconciledCookieConsent, useReconciledCookieConsent } from './use-reconciled-cookie-consent'
import { useStoreCookieConsentRemotely } from './use-store-cookie-consent-remotely'
import { dnt, gpc, usePrevious } from './util'

const { Events: E } = Analytics

const STATIC_ANALYTICS_PROPERTIES = {
  // in line with what we bubble up from enhanced tracking events
  platform: 'webapp',
}

const resolveAnalyticsProperties = (payload: ConsentManagementConsentPayload) => {
  const purposes = payload.purposes
  return {
    ...STATIC_ANALYTICS_PROPERTIES,
    Advertising: purposes.advertising,
    Analytics: purposes.analytics,
    Confirmed: payload.confirmed,
    Dt_confirmed: payload.dt_confirmed.toISOString(),
    dnt: `${dnt()}`,
    Essential: purposes.essential,
    Functional: purposes.functional,
    gpc: `${gpc()}`,
    regimes: JSON.stringify(payload.regimes),
    SaleOfInfo: purposes.saleOfInfo,
  }
}

/**
 * hook intended to "initialize" the Consent Management framework:
 * - reconciles the most recent confirmed consent between local and remote services
 * - stores local consent remotely, if confirmed and newer
 * - returns a boolean indicative of whether the consent management library has been initialized
 */
const useConsentManagementInitializer = () => {
  const logger = useLogger('useConsentManagementInitializer')

  const { consentManagement } = useAmplitude()
  const previousConsentManagement = usePrevious(consentManagement)

  const reconciledConsentResolver = useReconciledCookieConsent()
  const storeConsentRemotely = useStoreCookieConsentRemotely()

  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  useEffect(() => {
    if (consentManagement !== undefined && previousConsentManagement === undefined) {
      // Consent Management was just initialized with a non-undefined value; begin initialization
      reconciledConsentResolver()
        .then((resolvedConsent: ReconciledCookieConsent) => {
          const { source, consent } = resolvedConsent
          if (source === 'remote') {
            // use Remote Consent to set up Local Consent
            consentManagement.setConsent(consent)
          }

          // and we've initialized!
          setIsInitialized(true)

          if (source === 'local' && consent.confirmed) {
            // store Local Consent (confirmed by the user) on our backend servers
            return storeConsentRemotely(consent)
          }
        })
        .catch((error?: any) => {
          logger.error('error reconciling', `error: ${error?.message}`)
        })
    }
  }, [consentManagement])

  return isInitialized
}

/**
 * wrapper hook that:
 * -- delegates necessary calls for initializing the Consent Management library, and,
 * -- reports the following events to Analytics:
 *  - the Cookie Management library is initialized
 *  - the Consent chosen by the user is modified
 */
export const useConsentManagementEventListeners = () => {
  const logger = useLogger('useConsentManagementEventListeners')

  const { consentManagement } = useAmplitude()
  const previousConsentModifiedAt = usePrevious(consentManagement?.consentUpdatedAt)

  const isConsentManagementInitialized = useConsentManagementInitializer()
  useEffect(() => {
    if (isConsentManagementInitialized && consentManagement !== undefined) {
      logEvent(E.CUSTOMER_COOKIE_CONSENT_INITIALIZED, resolveAnalyticsProperties(consentManagement.getConsent()))
    }
  }, [isConsentManagementInitialized])

  const { logEvent } = useAnalytics()
  const storeConsentRemotely = useStoreCookieConsentRemotely()

  useEffect(() => {
    if (consentManagement === undefined || !isConsentManagementInitialized) {
      // nothing much to do while Consent Management isn't available and initialized
      return
    }

    // check if the consent was modified based on the timestamp surfaced
    if (previousConsentModifiedAt !== consentManagement.consentUpdatedAt) {
      logEvent(E.CUSTOMER_COOKIE_CONSENT_SELECTED, {
        ...resolveAnalyticsProperties(consentManagement.getConsent()),
        location: 'banner',
      })

      storeConsentRemotely(consentManagement.getConsent()).catch((error?: any) => {
        logger.error('error storing user consent to remote', `error: ${error?.message}`)
      })
    }
  }, [consentManagement, isConsentManagementInitialized])
}
