import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { AirgapAPI, ConsentChangeEventPayload } from '@transcend-io/airgap.js-types'

import { useLogger } from '../logger'

type SetState<T> = Dispatch<SetStateAction<T | undefined>>

export interface ConsentManagementProxy {
  consentUpdatedAt?: number
  getConsent: () => ConsentManagementConsentPayload & { readonly prompted?: boolean }
  setConsent: (consent: ConsentManagementConsentPayload) => void
}

const loadAirgapJs = async (airgapScriptSrc: string, onLoadEvt: SetState<Event>): Promise<AirgapAPI> => {
  // https://docs.transcend.io/docs/articles/consent-management/configuration/loading-asynchronously
  return new Promise((resolve, reject) => {
    const readyQueue = window?.airgap?.readyQueue || []
    const airgap = (window.airgap = window.airgap || {
      readyQueue,
      ready: (callback: AirgapReadyCallback) => {
        readyQueue.push(callback)
      },
    })

    const head = document.getElementsByTagName('head')[0],
      script = document.createElement('script')

    // add Promise resolution callbacks
    airgap.ready((realAirgap: AirgapAPI) => {
      // once airgap.js is ready, force a cross-domain sync before resolving the overall Promise
      realAirgap
        .sync()
        .catch(() => {
          // no-op
        })
        .finally(() => {
          resolve(realAirgap)
        })
    })
    script.addEventListener('error', reject)

    // initialize script tag and inject into DOM
    script.async = script.defer = true
    script.src = airgapScriptSrc
    script.onload = onLoadEvt
    script.dataset.consentExpiry = '525600'
    script.dataset.csp = 'off'
    script.dataset.defaultRegime = 'US_DNSS'
    script.dataset.dismissedViewStatus = 'Closed'
    script.dataset.privacyPolicy = 'https://getflex.com/privacy/'
    script.dataset.prompt = 'auto'
    script.dataset.protect = 'on'
    script.dataset.regulateAllScripts = 'on'
    script.dataset.regulateCookies = 'on'
    script.dataset.uiAllowInEmbeds = 'off'
    script.dataset.unknownCookiePolicy = 'allow'
    script.dataset.unknownRequestPolicy = 'allow'
    head.append(script)
  })
}

const useAirgapAPI = () => {
  // state variable to hold onto the airgap.js instance
  const [airgap, setAirgap] = useState<AirgapAPI>()

  // state variable to notify of consent changes bubbled up via the airgap.js consent-change event listener
  const [consentModifiedAt, setConsentModifiedAt] = useState<number>()

  // to programmatically interact with consent, we need a "trusted" DOM event. for more information, read:
  // https://docs.transcend.io/docs/articles/consent-management/configuration/creating-your-own-ui#consent-authorization
  // this hook surfaces the script onload event captured by this state variable
  const [scriptLoadEvent, setScriptLoadEvent] = useState<Event>()

  // memoized proxy wrapped around the airgap.js instance abstracting away the library-specifics from end-user
  const proxy: ConsentManagementProxy | undefined = useMemo(() => {
    if (airgap === undefined || scriptLoadEvent === undefined) {
      return undefined
    }

    const consentModificationTime = consentModifiedAt || Date.parse(airgap.getConsent().timestamp)
    return {
      consentUpdatedAt: consentModificationTime,
      getConsent: () => {
        const consent = airgap.getConsent()
        const purposes = consent.purposes as Record<string, boolean | undefined>
        return {
          prompted: consent.prompted,
          confirmed: consent.confirmed,
          dt_confirmed: new Date(Date.parse(consent.timestamp)),
          regimes: Array.from(airgap.getRegimes()),
          purposes: {
            advertising: purposes.Advertising,
            analytics: purposes.Analytics,
            essential: purposes.Essential,
            functional: purposes.Functional,
            // MRTCH-121: We are intentionally returning the Advertising consent as the Sale of Information consent
            saleOfInfo: purposes.Advertising,
          },
        }
      },
      setConsent: (consent: ConsentManagementConsentPayload) => {
        const { purposes } = consent
        airgap.setConsent(
          scriptLoadEvent!,
          {
            Advertising: purposes.advertising,
            Analytics: purposes.analytics,
            Functional: purposes.functional,
            // MRTCH-121: Regardless of what value is given, we intentionally ignore the Sale of Information consent
            //   value and do NOT pass it on to our Consent Management library!
            // SaleOfInfo: purposes.saleOfInfo,
          },
          {
            prompted: true,
            confirmed: consent.confirmed,
            timestamp: consent.dt_confirmed.toISOString(),
          }
        )
        setConsentModifiedAt(new Date().getTime())
      },
    }
  }, [airgap, consentModifiedAt])

  // effect to wire event listeners to the airgap.js instance when initialized and available
  useEffect(() => {
    if (airgap === undefined) {
      return
    }

    airgap.addEventListener('consent-change', (evt: ConsentChangeEventPayload) => {
      const newConsent = evt.detail.consent
      if (newConsent === undefined || newConsent === null || newConsent.confirmed === false) {
        // not much we can do in the way of reporting a "newly" selected consent
        return
      }

      // trigger a state change so the memoized proxy gets updated letting listeners know of the consent-change event
      setConsentModifiedAt(new Date().getTime())
    })
  }, [airgap])

  // and it's a wrap; return
  return { setAirgap, setScriptLoadEvent, proxy }
}

export const useConsentManagement = (
  uid: string | null,
  isAnonymous: boolean,
  shouldLoadLibrary: () => boolean,
  scriptSrc?: string
) => {
  const logger = useLogger('ConsentManagement')

  // boolean indicating whether the Consent Management script was loaded or not
  // null => no script to load, or user isn't logged in, or user is logged in and loadAirgapJs promise is pending
  // true => user is logged in and loadAirgapJs promise successfully resolved; ie: script has loaded and initialized
  // false => user is logged in and either loadAirgapJs promise failed with a rejection or the feature flag is off
  const [isLoaded, setIsLoaded] = useState<boolean | null>(null)

  // use custom AirgapAPI hook to wire things up correctly
  const {
    setAirgap: onAirgapInitialized,
    setScriptLoadEvent: setTrustedEvent,
    proxy: consentManagementProxy,
  } = useAirgapAPI()

  useEffect(() => {
    if (scriptSrc === undefined || isLoaded) {
      return
    }

    if (uid !== null && !isAnonymous) {
      if (shouldLoadLibrary()) {
        loadAirgapJs(scriptSrc, setTrustedEvent)
          .then((airgap: AirgapAPI) => {
            onAirgapInitialized(airgap)
            setIsLoaded(true)
          })
          .catch((error) => {
            logger.error('loadAirgapJs', error)
            setIsLoaded(false)
          })
      } else {
        setIsLoaded(false)
      }
    }
  }, [scriptSrc, isLoaded, uid, isAnonymous])

  // for a consumer of this hook, Consent Management is considered:
  // - "initialized" when it is safe to load other libraries into the DOM because there was an attempt made to load the script
  // - "loaded" when the Consent Management async script is successfully fetched and the ready stub from the script fires as expected
  return {
    isInitialized: scriptSrc === undefined || isLoaded !== null,
    isLoaded: isLoaded === true,
    consentManagementProxy,
  }
}

export interface ConsentManagementConsentPayload {
  readonly confirmed: boolean
  readonly dt_confirmed: Date
  readonly regimes: string[]
  readonly purposes: {
    readonly advertising?: boolean
    readonly analytics?: boolean
    readonly essential?: boolean
    readonly functional?: boolean
    readonly saleOfInfo?: boolean
  }
}
