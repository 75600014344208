import { OnboardingContentOverhaulExperiment, OnboardingContentOverhaulVariantValues } from '@genoa/experiments'

import { useExperimentVariant } from '../use-experiment-variant'

type ContentOverhaul = {
  inOnboardingExperiment: boolean
  onboardingEnabled: boolean
}

export const useContentOverhaul = (): ContentOverhaul => {
  const onboardingVariant = useExperimentVariant(OnboardingContentOverhaulExperiment)
  const onboardingTreatment = onboardingVariant === OnboardingContentOverhaulVariantValues.Treatment

  return {
    inOnboardingExperiment: !!onboardingVariant,
    onboardingEnabled: onboardingTreatment,
  }
}
