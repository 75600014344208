import React from 'react'
import { Analytics } from '@genoa/analytics'
import { OpenSearchProperty } from '@genoa/domain'
import { ADDRESS_SELECTION, CO_ADDRESS_SELECTION } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useContentOverhaul, useSecureLineOfCredit } from '../../../../../hooks'
import { Headline2, ScrollableSelectionList, SmallText, TextInput } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { LiveSomewhereElse } from '../LiveSomewhereElse'
import { AddressSelectionListItem } from './AddressSelectionListItem'
import { LocationData } from './LocationData'

export interface AddressSelectionProps {
  readonly analyticsScreenName: Analytics.Screens
  readonly onChangeQuery: (query: string) => void
  readonly properties: readonly OpenSearchProperty[]
  readonly onSelect: (property: OpenSearchProperty, index: number) => Promise<void>
  readonly loadingNext: boolean
  readonly onRequestLocationData: () => void
  readonly loadingLocationData: boolean
  readonly loadedLocationData: boolean
  readonly onDontSeeMyProperty: () => void
}

export const AddressSelection = (props: AddressSelectionProps) => {
  const theme = useTheme()
  const { onboardingEnabled } = useContentOverhaul()
  const { isFlagEnabledForCreditBuilder } = useSecureLineOfCredit()
  const content = onboardingEnabled && !isFlagEnabledForCreditBuilder ? CO_ADDRESS_SELECTION : ADDRESS_SELECTION

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Container>
        <Box marginTop="30px">
          <Headline2>{content.HEADER}</Headline2>
          <Box minH="10px" />
          <SmallText>{content.BODY}</SmallText>
          <Box minH="6px" />
          <LocationData
            onPress={props.onRequestLocationData}
            theme={theme}
            isLoading={props.loadingLocationData}
            isLoaded={props.loadedLocationData}
            buttonText={content.LOCATION_DATA}
          />
          <Box minH="10px" />
          <SmallText>{content.SEARCH_INPUT_LABEL}</SmallText>
          <Box minH="5px" />
          <TextInput
            onTextChange={props.onChangeQuery}
            placeholder={content.SEARCH_INPUT_PLACEHOLDER}
            testID="CustomerAddressInput"
          />
        </Box>
        <ScrollableSelectionList>
          {props.properties.map((item, index) => (
            <AddressSelectionListItem
              onClick={() => props.onSelect(item, index)}
              key={`unit-${index}`}
              index={index}
              property={item}
            />
          ))}
        </ScrollableSelectionList>
        <Box marginTop="12px">
          <LiveSomewhereElse onPress={props.onDontSeeMyProperty} theme={theme} buttonText={content.DONT_SEE_PROPERTY} />
        </Box>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
`
